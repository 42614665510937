import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import MyBlock from '../components/block'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SEO from '../components/seo'

const IndexPage = () => {
	return (
		<Layout pageTitle="Home Page">
			<SEO />
			<ScrollLogo />
			<MyBlock title="Want to discover a new culture?" icon="globe-americas" bg="white">
				<p> <FACES /> sets up home stay, cultural exchanges and trips to the USA and France for students aged 14-18.</p>
				<div className="list-photos">
					<div className="vignette-photo">
						<img src="/photo1.jpg" alt="photo1" />
					</div>
					<div className="vignette-photo">
						<img src="/photo2.jpg" alt="photo2" />
					</div>
					<div className="vignette-photo">
						<img src="/photo3.jpg" alt="photo3" />
					</div>
				</div>
			</MyBlock>
			<MyBlock title="Our network" icon="compass" color="white" bg="#FA5050">
				<p><FACES />  work in cooperation with an extensive network of reliable coordinators and teachers from Chartres, Orleans, Lille, Caen, Bordeaux, Biarritz, Nancy and many other cities in France.</p>
				<p><FACES />  is in contact with high schools, directors, teachers, students to develop educational tours and tools.</p>
				<p><FACES />  communicates with students and families to explain differences, culture shock, customs and tradition, what to expect from one another and what needs to be provided during the stay.</p>
				<Link to="destinations" className="link right zoom" style={{ color: "#003053", fontWeight: "bold" }}> Discover all of our destinations <FontAwesomeIcon icon="chevron-right" /> </Link>
			</MyBlock>
			<MyBlock title="Organisation" icon="book" bg="#7FD0FF">
				<p><span style={{ color: "#003053", fontWeight:"bold"}}>FACES</span> organizes the stay, coordinates communication, schedules the time that exchange students spend with host families, spend at school and spend visiting sites of interest.</p>
			</MyBlock>
			<MyBlock title="A word from the CEO" icon="comment-dots">
				<FontAwesomeIcon icon="quote-left" style={{ marginLeft: "5%" }} /><div className="quote">I think that it is our duty as responsible parents, educators and teachers to give our children the chances to develop not only the skills they need today to find an enjoyable and rewarding job but also the communication abilities, the values of respect and open mindedness that will make them better people tomorrow.</div><p style={{ textAlign: "right", marginRight: "10%" }}><FontAwesomeIcon icon="quote-right" /></p>
				<div className="center">
					<div className="vignette-photo col-8 col-sm-7 col-md-4 mx-auto" >
						<img src="/ceo.jpg" alt="ceo" />
					</div>
				</div>
				<p className="center">Jean-Marc HUMBERT <br />CEO</p>
			</MyBlock>
			
			{/*<MyBlock title="Reviews" icon="pencil-alt" bg="#FFB460">
				<Review from="Me">" It was a fantastic stay. "</Review>
				<Review from="Me again">" Amazing food, great service, we can’t wait to come back! "</Review>
			</MyBlock>*/}
		</Layout >
	)
}


const FACES = () => {
	return (
		<span style={{ color: "#003053", fontWeight: "bold" }}>FACES</span>
	)
}

const Review = ({ from, children }) => {
	return (
		<div class="review"><p>{children}</p><p class="signature"> {from}</p></div>
	)
}

const ScrollLogo = () => {

	React.useEffect(() => {
		document.getElementById('scroll-down-el').style.marginTop = Math.ceil(100 - (document.getElementById('scroll-down-el').getBoundingClientRect().bottom)/window.innerHeight*100) + "vh";
		});

	return (
		<div 
		id="scroll-down-el" className="center">
			<div className="scroll-down">Scroll down</div>
			<img src="/scroll-down.svg" style={{height: "10vh", width: "auto" }} />
		</div>
	)
}
export default IndexPage